import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import config from './config';
import App from './App';
import Modal from 'react-modal';

import './styles/IntroPage.css';  // Import global styles

// Client-side redirection script in case no www is used
if (window.location.hostname === 'songshake.com') {
  window.location.href = config.clientBaseUrl;
}

const container = document.getElementById('root');
const root = createRoot(container);

Modal.setAppElement(container);

root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </React.StrictMode>
);
