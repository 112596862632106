import React, { createContext, useContext } from 'react';

const ReloadContext = createContext();

export const useReload = () => useContext(ReloadContext);



export const ReloadProvider = ({ children, reloadKey }) => {
  const reload = () => {
    reloadKey = (prevKey => prevKey + 1);
  };

  return (
  <ReloadContext.Provider value={{reloadKey, reload}}>
    {children}
  </ReloadContext.Provider>
  );
};