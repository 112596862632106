import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchWrapper } from '../../_helpers/fetchWrapper';
import { FaTrash } from 'react-icons/fa';
import '../../styles/PerformerHome.css';
import styles from '../../styles/partial/PerformerEditTags.module.css';
import config from '../../config';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './navigation/PerformerNavEditTags';

const DroppableArea = React.memo(({ song, onDropTag, onDeleteTag, selectedSongs }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.TAG,
    drop: (item) => onDropTag(song.ID, item.tag),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop} style={{ backgroundColor: isOver ? 'lightgreen' : 'var(--main-content-card-background-color)'}} className="grid-item grid-item-frame">
      {(song.Tags || []).map((tag) => (
        <div key={tag.ID} style={{ backgroundColor: tag.BackgroundColor, color: tag.TextColor}} className="tags-content-container">
          {tag.ImageURL && <img src={config.mediaBaseUrl + '/' + tag.ImageURL} alt="Icon for tag" className="tag-image" />}
          <span>{tag.Word}</span>
          <div><FaTrash className="icon" onClick={() => onDeleteTag(song.ID, tag.ID)} style={{ cursor: 'pointer' }} /></div>
        </div>
      ))}
    </div>
  );
});

const PerformerEditTags = () => {
  const { bandid } = useParams();
  const fetchWrapper = useFetchWrapper();
  const [songs, setSongs] = useState([]);
  const [songsFetched, setSongsFetched] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectedSongs, setSelectedSongs] = useState([]);
  const latestSelectedSongsRef = useRef(selectedSongs);

  const fetchSongsData = useCallback(async () => {
    try {
      const data = await fetchWrapper.get(`${config.apiBaseUrl}/bands/${bandid}/songs`);
      setSongs(data);
      setSongsFetched(true);
    } catch (error) {
      console.error('Error fetching songs data:', error);
    }
  }, [bandid, fetchWrapper]);

  useEffect(() => {
    if (songsFetched) return;

    fetchSongsData();
  }, [songsFetched, fetchSongsData]);

  useEffect(() => {
    latestSelectedSongsRef.current = selectedSongs;
  }, [selectedSongs]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedSongs = React.useMemo(() => {
    let sortableSongs = [...songs];
    if (sortConfig.key !== null) {
      sortableSongs.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableSongs;
  }, [songs, sortConfig]);

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '';
  };

  const handleSelectedSong = (songId) => (event) => {
    setSelectedSongs((prevSelectedSongs) =>
      event.target.checked
        ? [...prevSelectedSongs, songId]
        : prevSelectedSongs.filter((id) => id !== songId)
    );
  };

  const handleSongTagDelete = (songId, tagId) => {
    setSongs((prevSongs) =>
      prevSongs.map((song) => {
        if (song.ID === songId) {
          return { ...song, Tags: song.Tags.filter((tag) => tag.ID !== tagId) };
        }
        return song;
      })
    );

    // Make POST request to unassign tag to song
    fetchWrapper.post(`${config.apiBaseUrl}/bands/${bandid}/tags/unassign`, {
      SongID: songId,
      TagID: tagId,
    }).catch(error => {
      console.error('Error assigning tag:', error);
    });
  };

  const handleSelectAll = () => {
    if (selectedSongs.length > 0) {
      setSelectedSongs([]);
    } else {
      const allSongIds = songs.map(song => song.ID);
      setSelectedSongs(allSongIds);
    }
  };

  const handleDropTag = (songId, tag) => {
    var songIdsToUpdate = [];
    if(latestSelectedSongsRef.current.includes(songId)) {
      songIdsToUpdate = latestSelectedSongsRef.current
    } else if (latestSelectedSongsRef.current.length === 0) {
      songIdsToUpdate = [songId];
    }

    setSongs((prevSongs) =>
      prevSongs.map((song) => {
        if (songIdsToUpdate.includes(song.ID)) {
          // Check if the tag already exists in the song's tags
          const tagExists = (song.Tags || []).some((t) => t.ID === tag.ID);
          if (!tagExists) {
            // Make POST request to assign tag to song
            fetchWrapper.post(`${config.apiBaseUrl}/bands/${bandid}/tags/assign`, {
              SongID: song.ID,
              TagID: tag.ID,
            }).catch(error => {
              console.error('Error assigning tag:', error);
            });
            return { ...song, Tags: [...(song.Tags || []), tag] };
          }
        }
        return song;
      })
    );
  };

  return (
    <div className="performer-content">
      <div className={styles.song_grid}>
        <div className="grid-header" onClick={() => handleSort('Title')}>Song {getSortIndicator('Title')}</div>
        <div className="grid-header" onClick={() => handleSort('Artist')}>Artist {getSortIndicator('Artist')}</div>
        <div className="grid-header">Tags</div>
        <div className="grid-header">
          <button onClick={handleSelectAll}>
            {selectedSongs.length > 0 ? 'Deselect All' : 'Select All'}
          </button>
        </div>
        {sortedSongs.map(song => (
          <React.Fragment key={song.ID}>
            <div className="grid-item grid-item-frame">{song.Title}</div>
            <div className="grid-item grid-item-frame">{song.Artist}</div>
            <DroppableArea song={song} onDropTag={handleDropTag} onDeleteTag={handleSongTagDelete} selectedSongs={latestSelectedSongsRef.current} />
            <div className="grid-item">
              <input type="checkbox" id={song.ID} onChange={handleSelectedSong(song.ID)} checked={selectedSongs.includes(song.ID)} />
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PerformerEditTags;