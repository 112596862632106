import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchWrapper } from '../../_helpers/fetchWrapper';
import '../../styles/PerformerHome.css';
import styles from '../../styles/partial/PerformerSongList.module.css';

import config from '../../config';
import Switch from '../Switch';
import EditSongModal from '../partial/modal/EditSongModal';
import { FaEdit, FaEllipsisV, FaTrash } from 'react-icons/fa';

const PerformerSongList = () => {
  const { bandid } = useParams();
  const fetchWrapper = useFetchWrapper();
  const [songs, setSongs] = useState([]);
  const [songsFetched, setSongsFetched] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [menuOpen, setMenuOpen] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentSong, setCurrentSong] = useState(null);
  const menuRefs = useRef({});

  const fetchSongsData = useCallback(async () => {
    try {
      const data = await fetchWrapper.get(`${config.apiBaseUrl}/bands/${bandid}/songs`);
      setSongs(data);
      setSongsFetched(true);
    } catch (error) {
      console.error('Error fetching songs data:', error);
    }
  }, [bandid, fetchWrapper]);

  useEffect(() => {
    if (songsFetched) return;

    fetchSongsData();
  }, [songsFetched, fetchSongsData]);

  const toggleActive = async (songId, currentStatus) => {
    try {
      await fetchWrapper.put(`${config.apiBaseUrl}/bands/${bandid}/songs/${songId}`, {
        Active: !currentStatus,
      });
      setSongs((prevSongs) =>
        prevSongs.map((song) =>
          song.ID === songId ? { ...song, Active: !currentStatus } : song
        )
      );
    } catch (error) {
      console.error('Error updating song status:', error);
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedSongs = React.useMemo(() => {
    let sortableSongs = [...songs];
    if (sortConfig.key !== null) {
      sortableSongs.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableSongs;
  }, [songs, sortConfig]);

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '';
  };

  const handleMenuToggle = (songId) => {
    setMenuOpen(menuOpen === songId ? null : songId);
  };

  const handleEdit = (song) => {
    setCurrentSong(song);
    setEditModalOpen(true);
    setMenuOpen(null);
  };

  const handleDelete = async (songId) => {
    try {
      await fetchWrapper.delete(`${config.apiBaseUrl}/bands/${bandid}/songs/${songId}`);
      setSongs((prevSongs) => prevSongs.filter((song) => song.ID !== songId));
      setMenuOpen(null);
    } catch (error) {
      console.error('Error deleting song:', error);
    }
  };

  const handleClickOutside = (event) => {
    if (menuRefs.current && !menuRefs.current.contains(event.target)) {
      setMenuOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const closeModal = () => {
    setEditModalOpen(false);
    setCurrentSong(null);
  };

  const handleSave = async (updatedSong) => {
    try {
      await fetchWrapper.put(`${config.apiBaseUrl}/bands/${bandid}/songs/${updatedSong.ID}`, updatedSong);
      setSongs((prevSongs) =>
        prevSongs.map((song) =>
          song.ID === updatedSong.ID ? updatedSong : song
        )
      );
      closeModal();
    } catch (error) {
      console.error('Error saving song:', error);
    }
  };

  return (
    <div className="performer-content" ref={menuRefs}>
      <div className={styles.song_grid}>
        <div className="grid-header" onClick={() => handleSort('Title')}>Song {getSortIndicator('Title')}</div>
        <div className="grid-header" onClick={() => handleSort('Artist')}>Artist {getSortIndicator('Artist')}</div>
        <div className="grid-header" onClick={() => handleSort('Queue')}>Queue {getSortIndicator('Queue')}</div>
        <div className="grid-header" onClick={() => handleSort('Key')}>Key {getSortIndicator('Key')}</div>
        <div className="grid-header" onClick={() => handleSort('Requested')}>Requested {getSortIndicator('Requested')}</div>
        <div className="grid-header" onClick={() => handleSort('Active')}>Active {getSortIndicator('Active')}</div>
        <div className="grid-header" onClick={() => handleSort('Actions')}>Actions</div>
        {sortedSongs.map(song => (
          <div key={song.ID} className={`${styles.song_grid_row} ${menuOpen === song.ID ? styles.highlightedRow : ''}`}>
            <div className={`grid-item ${menuOpen === song.ID ? styles.highlightedRow : 'grid-item-frame'}`}>{song.Title}</div>
            <div className={`grid-item ${menuOpen === song.ID ? styles.highlightedRow : 'grid-item-frame'}`}>{song.Artist}</div>
            <div className="grid-item queue-circle">+</div>
            <div className="grid-item grid-item-frame">{song.Key}</div>
            <div className="grid-item grid-item-frame">0 Times</div>
            <div className="grid-item">
              <Switch
                isOn={song.Active}
                handleToggle={() => toggleActive(song.ID, song.Active)}
                switchId={`switch-${song.ID}`}
              />
            </div>
            <div className={`grid-item ${styles.contextMenuContainer}`}>
              <button onClick={() => handleMenuToggle(song.ID)}>
                <FaEllipsisV />
              </button>
              {menuOpen === song.ID && (
                <div className={styles.contextMenu}>
                  <button onClick={() => handleEdit(song)}><FaEdit className="icon" /> Edit</button>
                  <button onClick={() => handleDelete(song.ID)}><FaTrash className="icon" /> Delete</button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <EditSongModal
        song={currentSong}
        isOpen={editModalOpen}
        onClose={closeModal}
        onSave={handleSave}
      />
    </div>
  );
};

export default PerformerSongList;