import React, { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { ReloadProvider } from '../../../contexts/ReloadContext';

import '../../../styles/partial/layout/BandLayoutPage.css';

import Logo from '../../Logo';
import TopPageNavigationBand from '../../TopPageNavigationBand';

import '../../../App.css'
import '../../../styles/partial/modal/modal.css'


const BandLayoutPage = ({ children, previousPage, navigation }) => {
  const navigate = useNavigate();
  const { bandid } = useParams();
  const [reloadKey, setReloadKey] = useState(0);

  const reloadChildren = () => {
    setReloadKey(prevKey => prevKey + 1);
  };

  if (bandid && previousPage) {
    // Modify the previousPage URL to include the ID
    previousPage = previousPage.replace(':bandid', bandid);
  }

  return (
    <div className="performer-page">
      <Logo />
      <TopPageNavigationBand />
      <div className="performer-page-wrapper">
        <div className="performer-page-content" key={reloadKey}>
          {previousPage && (
            <div className="back-arrow" onClick={() => navigate(previousPage)}>
              <FaArrowLeft /> Back
            </div>
          )}
            {children}
        </div>

        {navigation && (
          <div className="performer-page-right-side-navigation">
            <ReloadProvider value={reloadChildren}>
              {navigation}
            </ReloadProvider>
          </div>
        )}
      </div>

    </div>
  );
};

export default BandLayoutPage;