import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';

import config from '../../../config';
import CryptoJS from 'crypto-js';

import LoadingSpinner from '../LoadingSpinner';

const ImportSonglistModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    // Generate a random 32-character alphanumeric string
    const generateVerifier = () => {
      const array = new Uint32Array(32);
      window.crypto.getRandomValues(array);
      return Array.from(array, dec => ('0' + dec.toString(36)).substr(-2)).join('');
    };

    // Create an S256 hash of the random string
    const generateChallenge = async (verifier) => {
      const hash = CryptoJS.SHA256(verifier);

      return CryptoJS.enc.Base64.stringify(hash)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    };

    const verifier = generateVerifier();

    generateChallenge(verifier).then(challenge => {
      localStorage.setItem('verifier', verifier);
      localStorage.setItem('challenge', challenge);
      setLoading(false);
    });

  }, []);

  const { bandid } = useParams();
  const [loading, setLoading] = useState(true);
  const { apiBaseUrl } = config;

  const handleStreamerSonglistClick = () => {
    setLoading(true);

    const challenge = localStorage.getItem('challenge');
    localStorage.setItem('oauth-provider', 'twitch');

    // Set localStorage redirect
    localStorage.setItem('redirect', `/performers/${bandid}/import/songs`);

    // Open new window for Twitch OAuth
    const twitchOAuthUrl = `${apiBaseUrl}/auth/twitch/login?challenge=${challenge}`;
    const newWindow = window.open(twitchOAuthUrl, '_blank');

    // Check if the new window is closed
    const interval = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(interval);
        setLoading(false);
        onClose();
      }
    }, 1000);
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modalContent" overlayClassName="modalOverlay">
      <div className="modalContent">
        <h2>Import Songlist</h2>
        <div className="form-group">
        {loading ? (
          <LoadingSpinner />
          ) : (
            <button className="streamerSonglistButton" onClick={handleStreamerSonglistClick}>StreamerSonglist</button>
          )}
        </div>
        <div className="form-group">
          <button onClick={onClose} className="closeButton">Cancel</button>
        </div>
      </div>
    </Modal>
  );
};

export default ImportSonglistModal;