import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useParams } from 'react-router-dom';
import { useFetchWrapper } from '../../_helpers/fetchWrapper';
import config from '../../config';

function StreamerSongListImport() {
    const fetchWrapper = useFetchWrapper();
    const { bandid } = useParams();
    const { apiBaseUrl } = config;
    const hasFetched = useRef(false);
    const [message, setMessage] = useState("Please wait while we import your songs.");

    const fetchCallback = useCallback(async () => {
      // Extract query parameters from the current URL
      const queryParams = new URLSearchParams(window.location.search);

      // Retrieve the verifier from localStorage
      const verifier = localStorage.getItem('verifier');
      const code = localStorage.getItem('code');

      console.log('verifier:', verifier);
      if (!verifier) {
        console.error('Verifier not found in localStorage');
        setMessage("Error: Please close this window and try to import your songs again.");
        return;
      }

      console.log('code:', code);
      if (!code) {
        console.error('code not found in localStorage');
        setMessage("Error: Please close this window and try to import your songs again.");
        return;
      }

      if (!queryParams.has('verifier')) {
        queryParams.append('verifier', verifier);
      }

      if (!queryParams.has('code')) {
        queryParams.append('code', code);
      }

      try {
        fetchWrapper.get(`${apiBaseUrl}/bands/${bandid}/import/songs/ssl?${queryParams.toString()}`)
        .then((data) => {
          console.log('Import successful:', data);
          setMessage("Success! Your songs have been imported. You may now close this window.");
        })
        .catch((error) => {
          console.error('Error importing songs:', error);
          setMessage("Error: Please close this window and try to import your songs again.");
        });

      } catch (error) {
        console.error('Failed to complete OAuth callback:', error);
        setMessage("Error: Please close this window and try to import your songs again.");
      }
      finally {
        //remove the redircet from localStorage
        localStorage.removeItem('redirect');
      }

    }, [fetchWrapper, apiBaseUrl, bandid]);


    useEffect(() => {
      if (!hasFetched.current) {
        hasFetched.current = true;
        fetchCallback();
      }
    }, [fetchCallback]);

  return (
    <div className="oauth-page">
      <div className="oauth-page-content">
        <h1>Importing...</h1>
        <p>{message}</p>
      </div>
    </div>
  );
}

export default StreamerSongListImport;