import React, { useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/OAuthPage.css';  // Import styles
import config from '../config';

import { useTheme } from '../ThemeContext';
import { useFetchWrapper } from '../_helpers/fetchWrapper';

const { apiBaseUrl } = config;

function OAuthPage() {
    const navigate = useNavigate();
    const { setTheme } = useTheme();
    const fetchWrapper = useFetchWrapper();
    const hasFetched = useRef(false);


  const fetchCallback = useCallback(async () => {
    // Extract query parameters from the current URL
    const queryParams = new URLSearchParams(window.location.search);

    // Retrieve the verifier from localStorage
    const verifier = localStorage.getItem('verifier');
    const provider = localStorage.getItem('oauth-provider');

    // Retrieve the code from the query parameters
    localStorage.setItem('code', queryParams.get('code'));

    console.log('verifier:', verifier);
     // Add the verifier to the query parameters if it's not already in there
     if (!queryParams.has('verifier')) {
      if (!verifier) {
        console.error('Verifier not found in localStorage');
        navigate('/login');
        return;
      }
      queryParams.append('verifier', verifier);
    }

    if (!queryParams.has('provider')) {
      if (!provider) {
        console.error('Provider not found in localStorage');
        navigate('/login');
        return;
      }
    }

    // if the session storage has a redirect url, redirect to that url
    console.log('redirect:', localStorage.getItem('redirect'));
    if (localStorage.getItem('redirect')) {
      console.log('Query params:', queryParams.toString());
      navigate(localStorage.getItem('redirect') + '?' + queryParams);
      return
    }
    
    try {
      fetchWrapper.get(`${apiBaseUrl}/auth/${provider}/callback?${queryParams.toString()}`, undefined, true)
      .then((data) => {
        // Save the JWT token in localStorage
        sessionStorage.setItem('jwt', data.token);

        //set the userId from the jwt token
        const jwt = data.token
        const base64Url = jwt.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const payload = JSON.parse(window.atob(base64));
        const userId = payload.user_id;
        sessionStorage.setItem('userId', userId);

        fetchWrapper.get(`${apiBaseUrl}/user/profile/${userId}`)
        .then((data) => {
          // Save the user's profile image URL in localStorage
          const profileImageURL = data.profile_image_url || null;
          sessionStorage.setItem('ProfileImageURL', profileImageURL);

          //gets the theme from the data object and triggers a theme change
          sessionStorage.setItem('theme', data.theme);
          const theme = data.theme || config.defaultTheme;
          setTheme(theme);

          // Redirect to the users default page (home if unset)
          if (data.default_login_page !== undefined && data.default_login_page !== null && data.default_login_page !== '') {
            navigate(data.default_login_page);
          } else {
            navigate('/home');
          }
        })
      })

    } catch (error) {
      console.error('Failed to complete OAuth callback:', error);
      navigate('/login');
    }
  }, [fetchWrapper, navigate, setTheme]);

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      fetchCallback();
    }
  }, [fetchCallback]);

  return (
    <div className="oauth-page">
      <div className="oauth-page-content">
        <h1>Authenticating...</h1>
        <p>Please wait while we authenticate your account.</p>
      </div>
    </div>
  );
}

export default OAuthPage;